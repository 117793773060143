<template>
  <section id="TypesOfServiceMobile">
    <div class="service">
      <!-- <div class="service-top">
        <img src="@/assets/icons/Food/levy-logo.png" alt="levy" class="service-logo" />
        <h2>
          TYPES <br class="service-top-small"> OF SERVICES
          <div class="line"></div>
        </h2>
        <p>
          Levy represents the best in creative, versatile, and custom menu offerings for corporate and
          private groups. With service at the heart of everything they do, Levy will impress and
          delight each guest. The spectacular sales, operations, and culinary team will manage
          catered events like you've never seen before. Drawing experience from over 164 venues nationwide
          - Levy will bring your dreams to life and then some!
        </p>
      </div> -->
      <div class="service-bottom">
        <div class="item">
          <img src="@/assets/images/Food/food-circle-3.png" alt="food" />
          <h1>Chefs Table</h1>
          <p>Levy's signature spin on the tradtional buffet.</p>
        </div>
        <div class="item">
          <img src="@/assets/images/Food/food-circle-2.png" alt="food" />
          <h1>Plated Meals</h1>
          <p>Fresh, house-made, and packed with the best flavors, Levy’s premier array of innovative entrées are sure to impress your guests.</p>
        </div>
        <div class="item">
          <img src="@/assets/images/Food/food-circle-1.png" alt="food" />
          <h1>Reception Action Stations</h1>
          <p>Our chef-driven fare brings the heart of the kitchen into your reception. Guests can mingle over miniature restaurant plates finished guest-facing by our friendly chef attendants.</p>
        </div>
        <div class="item">
          <img src="@/assets/images/Food/food-circle-4.png" alt="food" />
          <h1>"Mega Menus"</h1>
          <p>Custom designed menus unifying concessions, chef’s tables, action stations, and local culinary partners to accommodate large groups of 5,000 and beyond.</p>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
  export default {
    name: "TypesOfServiceMobile"
  }
</script>

<style lang="scss" scoped>
  #TypesOfServiceMobile {
    width: 100vw;
    background: url('~@/assets/images/MobileBG/Food-and-Beverage-Section-2-bg.jpg') center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    .service {
      margin-top: 120px;
      margin: 0 100px;
      @media (max-width: $md) {
        margin: 0 50px;
      }
      @media (max-width: $sm) {
        margin: 0 20px;
      }
      &-top {
        display: flex;
        flex-direction: column;
        .service-logo {
          align-self: flex-end;
          height: 116px;
          width: 152px;
          margin-top: 40px;
          @media (max-width: 992px) {
            margin-right: 45px;
          }
          @media (max-width: $sm) {
            height: 62px;
            width: 81px;
            margin-right: 20px;
          }
        }
        h2 {
          font-family: 'ArtegraSans-Bold';
          font-size: 26px;
          color: #FFFFFF;
          letter-spacing: 1.62px;
          line-height: 25px;
          width: fit-content;
          .line {
            height: 2px;
            width: auto;
            background: #FFFFFF;
            box-shadow: 0 0 9px 2px #FFFFFF;
            margin: 20px 0;
          }
        }
        &-small {
          @media (min-width: $sm) {
            display: none;
          }
        }
        p {
          font-family: 'ArtegraSans-Regular';
          font-size: 15px;
          color: #FFFFFF;
          letter-spacing: 1px;
          line-height: 22px;
          // max-width: 794px;
          margin-right: 25px;
        }
      }
      &-bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        padding-bottom: 74px;
        @media (max-width: $md) {
          flex-direction: column;
        }
        .item {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #FFFFFF;
          text-align: center;
          margin-top: 20px;
          @media (max-width: $md) {
            width: 100%;
          }
          img {
            width: 200px;
          }
          h1 {
            font-family: 'ArtegraSans-Bold';
            font-size: 18px;
            letter-spacing: 1.12px;
            line-height: 22px;
            text-transform: uppercase;
            margin: 14px 0 10px 0;
          }
          p {
            font-family: 'ArtegraSans-Regular';
            font-size: 15px;
            letter-spacing: 1px;
            line-height: 22px;
            max-width: 594px;
            margin: 0 15px;
          }
        }
      }
    }
  }
</style>