<template>
  <section id="FoodBeverageMobile">
    <LevyMobile />
    <SilverBlackHospitality />
    <MeetTheChefMobile />
    <TypesOfServiceMobile />
  </section>
</template>

<script>
  import LevyMobile from '@/components/sections/FoodBeverage/Levy/LevyMobile'
  import SilverBlackHospitality from '@/components/sections/FoodBeverage/SilverBlackHospitality/SilverBlackHospitality'
  import MeetTheChefMobile from '@/components/sections/FoodBeverage/MeetTheChef/MeetTheChefMobile'
  import TypesOfServiceMobile from '@/components/sections/FoodBeverage/TypesOfService/TypesOfServiceMobile'

  export default {
    name: "FoodBeverageMobile",
    components: {
      LevyMobile,
      SilverBlackHospitality,
      MeetTheChefMobile,
      TypesOfServiceMobile
    }
  }
</script>

<style lang="scss" scoped>
  #FoodBeverageMobile {
    margin: 0;
  }
</style>