<template>
  <section class="hospitalityTemplate">
    <div class="titleWrapper">
      <div class="title">
        <img
          src="@/assets/images/Food/sbhos.png"
          alt="silverblack"
          class="silverblack-logo"
        />
        <div class="line"></div>
        <p>
          Allegiant Stadium is in the heart of one of the world's true culinary
          epicenters, and Silver & Black Hospitality has brought the absolute
          best of Vegas' culinary and beverage community into this venue.
          Featuring locally inspired restaurants, clients can add a taste of Las
          Vegas to cultivate a truly unique menu that represents the best in
          food and beverage.
        </p>
      </div>
      <div class="logo-row">
        <div class="logo">
          <div class="logo-pic">
            <img
              src="@/assets/icons/Food/nation.svg"
              alt="levy"
              class="nation-logo"
            />
          </div>
          <p>
            Add Stadium flair and Raiders history to your menu with these fan
            favorites.
          </p>
        </div>

        <div class="logo">
          <div class="logo-pic">
            <img
              src="@/assets/icons/Food/neondog.svg"
              alt="levy"
              class="neondog-logo"
            />
          </div>
          <p>
            Your guests will reimagine the possibilities of what a hot dog can
            be with these loaded dogs.
          </p>
        </div>

        <div class="logo">
          <div class="logo-pic">
            <img
              src="@/assets/icons/Food/Pizza-Rock-Logo.png"
              alt="levy"
              class="pinkbox doughnuts"
            />
          </div>
          <p>
            Local Las Vegas Favorite named on the list of the 50 best pizzerias
            in the U.S. for 2023 by Italy based 50 Top Pizza.
          </p>
        </div>

        <div class="logo">
          <div class="logo-pic">
            <img
              src="@/assets/icons/Food/Pinkbox_Doughnuts_Logo.png"
              alt="levy"
              class="pinkbox doughnuts"
            />
          </div>
          <p>
            Local Las Vegas Favorite and the official doughnut of the Las Vegas
            Raiders.
          </p>
        </div>

        <!-- <div class="logo">
          <div class="logo-pic">
            <img src="@/assets/icons/Food/2pizza.svg" alt="levy" class="pizza2-logo" />
          </div>
          <p>Featuring square slices and unbeatable taste, this crowd pleaser is a perfect addition to any menu.</p>
        </div> -->

        <!-- <div class="logo">
          <div class="logo-pic">
            <img src="@/assets/icons/Food/area41.svg" alt="levy" class="area41-logo" />
          </div>
          <p>The ½ yard signature ET cocktail created by Mixologist Tony Abou-Ganim is a conversation starter at any event.</p>
        </div> -->

        <div class="logo">
          <div class="logo-pic">
            <img
              src="@/assets/icons/Food/1960.svg"
              alt="levy"
              class="the1960-logo"
            />
          </div>
          <p>
            Set apart your beverage experience with unique beer, wine, and
            liquor offerings.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MeetTheChef",
};
</script>

<style lang="scss" scoped>
.hospitalityTemplate {
  min-height: 100vh;
  width: 100vw;
  background-image: url("~@/assets/images/Food/silver-black-hos-page-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 993px) {
    min-height: auto;
  }
  .titleWrapper {
    position: relative;
    margin-top: 190px;
    margin-left: 351px;
    @media (max-width: $lg) {
      margin-left: 0;
      margin-top: 0;
      padding: 50px 0;
    }
    .title {
      display: flex;
      flex-direction: row;
      position: relative;
      margin: 0 50px;
      @media (max-width: $lg) {
        display: block;
      }
      .silverblack-logo {
        width: 190px;
        height: 105px;
        @media (max-width: $lg) {
          margin: auto;
        }
      }
      .line {
        width: 3px;
        height: 100px;
        background-color: #fff;
        box-shadow: 0 0 9px 2px #ffffff;
        margin: 0 50px;
        @media (max-width: $lg) {
          display: none;
        }
      }
      p {
        font-family: "ArtegraSans-Regular";
        font-size: 16px;
        color: #ffffff;
        line-height: 23px;
        margin: 0 50px 0 0;
        @media (max-width: $lg) {
          margin: 50px auto 0 auto;
          text-align: center;
        }
      }
    }
    .logo-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 80%;
      margin: 100px auto 0 auto;
      @media (max-width: $lg) {
        display: block;
      }
      .logo {
        flex: 1 0 26%;
        margin: 25px;
        .logo-pic {
          height: 100px;
          display: flex;
          img {
            margin: 0 auto;
          }
        }
        p {
          font-family: "ArtegraSans-Regular";
          font-size: 14px;
          color: #ffffff;
          line-height: 23px;
          text-align: center;
        }
        .nation-logo {
          width: 196px;
        }
        .neondog-logo {
          width: 211px;
        }
        .pizza2-logo {
          width: 131px;
        }
        .area41-logo {
          width: 152px;
        }
        .the1960-logo {
          width: 148px;
        }
      }
    }
  }
}
</style>